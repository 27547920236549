/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"

import { PageNav, Footer } from "../navigation"
// Styles
import { siteTheme } from "../../themes/theme.js"
import { GlobalStyles } from "../../css/globalStyles"

const StyledPageWrapper = styled.main`
  width: 100%;
  position: absolute;
  top: 0;
`

const Layout = ({ children, pageNavItems }) => {
  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles />
      {pageNavItems && <PageNav items={pageNavItems} />}
      <StyledPageWrapper>
        {children}
        <Footer />
      </StyledPageWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
