import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Headroom from "react-headroom"

// Import Common Components
import { StandardContainer, StyledNavLink } from "../common"
import { Logo, MenuToggle, MobileNav } from "./"
import { SiteNotification } from "../global/siteNotification"
import FacebookIcon from "../../svg/facebook.svg"
import InstaIcon from "../../svg/instagram.svg"

const StyledHeader = styled(Headroom)`
  z-index: 99999 !important;
  position: relative;
  header {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background: transparent;
    transition: 0.2s all ease;
    .gatsby-image-wrapper {
      width: 100px;
      transition: 0.2s all ease;
    }
    nav {
      display: none;
      .social-link {
        margin-left: 1.5rem;
        svg {
          width: 28px;
          margin-top: 0.2rem;
        }
      }
      ${StyledNavLink} {
        margin-left: 2rem;
      }
    }
  }

  .headroom--pinned {
    header {
      background: #000;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    header {
      nav {
        display: flex;
        align-items: center;
      }
      button {
        display: none;
      }
      .gatsby-image-wrapper {
        width: 160px;
      }
    }
    .headroom--pinned {
      header {
        .gatsby-image-wrapper {
          width: 120px;
        }
      }
    }
  }
`

export const PageNav = ({ items }) => {
  const { prismicGlobals } = useStaticQuery(graphql`
    query {
      prismicGlobals {
        data {
          facebook_link {
            url
          }
          instagram_link {
            url
          }
        }
      }
    }
  `)
  const { facebook_link, instagram_link } = prismicGlobals.data
  const [menuVisible, setMenuVisible] = useState(false)
  return (
    <>
      <StyledHeader>
        <SiteNotification />
        <StandardContainer as="header">
          <Logo />
          <nav>
            {items.map(item => (
              <StyledNavLink
                to={item.id}
                smooth={true}
                duration={800}
                spy={true}
              >
                {item.primary.nav_title}
              </StyledNavLink>
            ))}
            {facebook_link.url && (
              <a
                href={facebook_link.url}
                target="_blank"
                className="social-link"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            )}
            {instagram_link.url && (
              <a
                href={instagram_link.url}
                target="_blank"
                className="social-link"
                rel="noopener noreferrer"
              >
                <InstaIcon />
              </a>
            )}
          </nav>
          <button onClick={() => setMenuVisible(!menuVisible)}>
            <MenuToggle isOpen={menuVisible} />
          </button>
        </StandardContainer>
      </StyledHeader>
      <MobileNav
        items={items}
        menuVisible={menuVisible}
        onClose={() => setMenuVisible(false)}
      />
    </>
  )
}
