import { createGlobalStyle } from "styled-components"

// Define Fonts
import BrandonRegular from "../fonts/brandongrotesque-regular.woff"
import BrandonRegular2 from "../fonts/brandongrotesque-regular.woff2"
import BrandonBold from "../fonts/brandongrotesque-bold.woff"
import BrandonBold2 from "../fonts/brandongrotesque-bold.woff2"

export const GlobalStyles = createGlobalStyle`

    @font-face {
      font-family: 'brandon_regular';
      src: url(${BrandonRegular2}) format('woff2'),
            url(${BrandonRegular}) format('woff');
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
        font-family: 'brandon_bold';
        src: url(${BrandonBold2}) format('woff2'),
            url(${BrandonBold}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    html {
      text-rendering: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-x: hidden;
      box-sizing: border-box;
      overflow-x: hidden;
      font-family: ${props => props.theme.typography.family.standard};
    }
    /* Minimal Resets */
    * {
      font-weight: normal;
      word-wrap: break-word;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    } 
    #___gatsby {
      min-height: 100vh;
    }
    
    body, h1, h2, h3, h4, h5, h6, p, ol, ul {
      margin: 0;
      padding: 0;
      font-weight: normal;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    button {
      cursor: pointer;
      background: none;
      border: none;
    }
    a{
      color: inherit;
      text-decoration: none;
    }
    
    button:focus {
      outline: none;
    }
    strong {
      font-family: ${props => props.theme.typography.family.bold};
    }

    h1,h2,h3,h4,h5,h6{
      line-height: ${props => props.theme.typography.lineHeights.standard};
      font-family: ${props => props.theme.typography.family.heading};
      margin: 0.3rem 0;
    }
    h1 {
      font-size: ${props => props.theme.typography.sizes.h1};
      line-height: ${props => props.theme.typography.lineHeights.heading};
    }
    h2{
      font-size: ${props => props.theme.typography.sizes.h2};
      line-height: ${props => props.theme.typography.lineHeights.heading};
    }
    h4{
      font-family: ${props => props.theme.typography.family.bold};
      letter-spacing: 0.05em;
    }
    h3,h4,h5,h6 {
      font-size: ${props => props.theme.typography.sizes.h3};
    }
`
