import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { motion, AnimatePresence } from "framer-motion"

// Import common components
import { BodyCopy } from "../common"

// Styles
const StyledNotification = styled(motion.div)`
  background: #000;
  color: #fff;
  padding: 0 ${props => props.theme.padding.hMobile};
  text-align: center;
  position: relative;
  overflow: hidden;
  ${BodyCopy} {
    font-size: 14px;
    line-height: 1.4;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
  }
  button {
    color: #fff;
    position: absolute;
    top: 0.5rem;
    right: 0rem;
    font-size: 20px;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0.2rem ${props => props.theme.padding.hDesk};
    ${BodyCopy} {
      font-size: 18px;
    }
    button {
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

export const SiteNotification = () => {
  const { prismicGlobals } = useStaticQuery(graphql`
    query {
      prismicGlobals {
        data {
          notification_on
          notification_text {
            html
          }
        }
      }
    }
  `)
  const [dismiss, setDismiss] = useState(false)

  const { notification_on, notification_text } = prismicGlobals.data
  const isActive = notification_on === "True" && notification_text && !dismiss
  return (
    <AnimatePresence>
      {isActive && (
        <StyledNotification
          initial={{
            opacity: 1,
            height: "auto",
          }}
          exit={{
            opacity: 0,
            height: 0,
          }}
          transition={{
            type: "spring",
            damping: 300,
          }}
        >
          <BodyCopy
            dangerouslySetInnerHTML={{ __html: notification_text.html }}
          />
          <button onClick={() => setDismiss(true)}>X</button>
        </StyledNotification>
      )}
    </AnimatePresence>
  )
}
