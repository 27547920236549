import React from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"

// Import Common Components
import { StyledNavLink, StandardContainer } from "../common"

// Styles
const StyledMobileNav = styled(StandardContainer)`
  background: #000;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  > nav {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 100%;
  }
  ${StyledNavLink} {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0;
  }
`

// Animation

const variants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
    transition: {
      delay: 0.5,
    },
  },
}
const navVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: { staggerChildren: 0.1, staggerDirection: -1 },
  },
}

const linkVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
}

export const MobileNav = ({ items, menuVisible, onClose }) => {
  return (
    <AnimatePresence>
      {menuVisible && (
        <StyledMobileNav
          variants={variants}
          initial="closed"
          animate="open"
          exit="closed"
        >
          <motion.nav variants={navVariants}>
            {items.map(item => (
              <motion.div key={item.id} variants={linkVariants}>
                <StyledNavLink
                  to={item.id}
                  smooth={true}
                  duration={800}
                  delay={800}
                  onClick={onClose}
                >
                  {item.primary.nav_title}
                </StyledNavLink>
              </motion.div>
            ))}
          </motion.nav>
        </StyledMobileNav>
      )}
    </AnimatePresence>
  )
}
