import styled from "styled-components"
import is from "styled-is"
import { Link as ScrollLink } from "react-scroll"

export const BodyCopy = styled.div`
  font-size: ${props => props.theme.typography.sizes.regular};
  line-height: ${props => props.theme.typography.lineHeights.standard};
  a {
    text-decoration:underline;
  }
  p {
    margin: 0.5em auto;
  }
  ${is("textCenter")`
    text-align: center;
  `}
  ${is("wrappedStandard")`
    *{
      max-width: ${props => props.theme.typography.measures.standard};
    }
  `}
  ${is("wrappedStandard", "textCenter")`
    *{
      margin-left: auto;
      margin-right: auto;
    }
  `}
`
export const ExternalTextLink = styled.a`
  font-size: ${props => props.theme.typography.sizes.regular};
  font-family: ${props => props.theme.typography.family.bold};
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-decoration: underline;
`

export const StyledNavLink = styled(ScrollLink)`
  font-family: ${props => props.theme.typography.family.bold};
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
`
