import React from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import styled from "styled-components"

export const PrismicImage = ({ localFile, url, ...props }) => {
  return (
    <>
      {localFile ? (
        <Img {...localFile.childImageSharp} {...props} />
      ) : (
        <img src={url} {...props} alt="" />
      )}
    </>
  )
}

const StyledBackgroundFallback = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
`
const StyledBackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const PrismicBackgroundImage = ({ localFile, url, ...props }) => {
  return (
    <StyledBackgroundImage className="prismic-bg">
      {localFile ? (
        <BackgroundImage {...props} {...localFile.childImageSharp} />
      ) : (
        <StyledBackgroundFallback
          src={url}
          {...props}
          className="styled-bg"
        ></StyledBackgroundFallback>
      )}
    </StyledBackgroundImage>
  )
}
