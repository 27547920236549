import styled from "styled-components"
import is from "styled-is"
import { motion } from "framer-motion"

export const StandardContainer = styled(motion.div)`
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};

  ${is("wrappedStandard")`
    max-width: ${props => props.theme.containerWidths.standard};
    margin-left: auto;
    margin-right: auto;
  `}

  ${is("padVert")`
    padding-top: ${props => props.theme.padding.vMobile};
    padding-bottom: ${props => props.theme.padding.vMobile};
  `}

  @media ${props => props.theme.breakpoints.medium} {
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
    ${is("padVert")`
      padding-top: calc(${props =>
        props.theme.padding.vDesk} + 160 * (100vw - 320px) / 2180);
        padding-bottom: calc(${props =>
          props.theme.padding.vDesk} + 160 * (100vw - 320px) / 2180);
    `}
  }
`
