import styled from "styled-components"

export const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  padding: 1rem 0;
  font-size: ${props => props.theme.typography.sizes.regular};
  font-family: ${props => props.theme.typography.family.standard};
  &:focus {
    outline: none;
  }
`
