export const siteTheme = {
  breakpoints: {
    ns: "screen and (min-width: 30em)",
    medium: "screen and (min-width: 30em)",
    large: "screen and (min-width: 64em)",
    xl: "screen and (min-width: 94em)",
  },
  colors: {
    document: {
      background: "#ffffff",
    },
    text: {
      standard: "#000000",
      reversed: "#ffffff",
    },
    buttons: {
      background: "#000000",
      color: "#ffffff",
    },
  },
  typography: {
    family: {
      standard: "brandon_regular",
      bold: "brandon_bold",
      heading: "brandon_regular",
    },
    sizes: {
      regular: "20px",
      h1: "calc(45px + (80 - 45) * ((100vw - 320px) / (1800 - 320)))",
      h2: "calc(28px + (45 - 28) * ((100vw - 320px) / (1800 - 320)))",
      h3: "calc(19px + (21 - 19) * ((100vw - 320px) / (1800 - 320)))",
      h4: "18px",
    },
    lineHeights: {
      standard: "1.8",
      heading: "1.4",
    },
    letterSpacing: {
      standard: "0",
      nav: "0.81px",
    },
    measures: {
      large: "55em",
      medium: "45em",
      standard: "30em",
    },
  },
  padding: {
    hMobile: "1.4rem",
    hDesk: "4rem",
    vMobile: "4rem",
    vDesk: "6rem",
  },
  containerWidths: {
    narrow: "1200px",
    standard: "1360px",
  },
  pageSpacers: {
    small: "15px",
    medium: "30px",
    large: "100px",
  },
}
