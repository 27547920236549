import React from "react"
import styled from "styled-components"

// Import Common Components
import { StandardContainer, BodyCopy } from "../common"
import { Logo, EgmontLogo, BuiltByBrave } from "./"

// Styles
const StyledFooter = styled(StandardContainer)`
  background: #000;
  color: #fff;
  display: grid;
  grid-row-gap: 2rem;
  padding-top: ${props => props.theme.padding.vMobile};
  padding-bottom: ${props => props.theme.padding.vMobile};
  .gatsby-image-wrapper {
    width: 140px;
    margin: 0 auto;
  }
  ${BodyCopy} {
    .gatsby-image-wrapper {
      margin: 0.5rem auto;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 1fr 1fr 1fr;
    .gatsby-image-wrapper {
      margin-left: 0;
    }
    a {
      .gatsby-image-wrapper {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
`

export const Footer = () => {
  return (
    <StyledFooter as="footer">
      <div className="logo-wrapper">
        <Logo />
      </div>
      <BodyCopy textCenter>
        <p>
          &copy; The Catering Studio {new Date().getFullYear()}., All rights
          reserved.
        </p>
        <a
          href="https://bravedigital.nz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BuiltByBrave />
        </a>
      </BodyCopy>
      <a
        href="https://www.egmontstreet.co.nz/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <EgmontLogo />
      </a>
    </StyledFooter>
  )
}
